import React, { ReactNode } from "react"
import "./blog-page-list-container.module.scss"

interface BlogPageListContainerProps {
  children: ReactNode
}

export const BlogPageListContainer = ({
  children,
}: BlogPageListContainerProps) => {
  return (
    <section className="fr-blog-page-list fr-container">{children}</section>
  )
}
