import React, { useContext } from "react"
import "./form-stub.module.scss"
import { FormStubField } from "./formStubBanner-types"
import { ButtonParser } from "components/button/ButtonParser"
import { ButtonBuilder } from "_types/Button"
import { FormFieldsContext } from "contexts/FormFieldsContext"
import { useLayout } from "contexts/components/LayoutContext"

interface FormStubProps {
  buttons: ButtonBuilder[]
  fields?: FormStubField[]
}

export const FormStub = ({ fields, buttons }: FormStubProps) => {
  const { setEmailAddress } = useContext(FormFieldsContext)
  const setFields = (e: { target: HTMLInputElement }) => {
    const { value } = e?.target
    setEmailAddress(value)
  }
  const { isHomepage } = useLayout()

  return (
    <div className="form-stub u-flex-center-align">
      <div
        className={
          isHomepage
            ? ` form-stub__field `
            : ` form-stub__field form-stub__field--alternate-background`
        }
      >
        {fields &&
          fields.length > 0 &&
          fields.map(({ elements, id }) => {
            return (
              <React.Fragment key={id}>
                <label htmlFor={elements?.form_field?.value[0]?.codename}>
                  {elements?.label?.value}
                </label>

                <input
                  type={elements?.field_type?.value[0].codename}
                  name={elements?.form_field?.value[0].codename}
                  placeholder={
                    elements?.field_type?.value[0].codename === "email"
                      ? "email@example.com"
                      : "John Smith"
                  }
                  onBlur={setFields}
                />
              </React.Fragment>
            )
          })}
        {buttons.length > 0 && (
          <ButtonParser
            buttons={buttons}
            ctaColorVariantSolid={"solid-primary"}
            isSingleCTAButtonAlwaysOutline={!fields || fields.length === 0}
            extraClassNames={
              !fields || fields.length === 0 ? "" : "form-stub__button"
            }
          />
        )}
      </div>
    </div>
  )
}
