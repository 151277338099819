import React from "react"
import "./blog-date.module.scss"

interface BlogDateProps {
  date: string
  baseClassName: string
  extraClassNames?: string
}

export const BlogDate = ({
  date,
  baseClassName = "fr-date-badge",
  extraClassNames = "",
}: BlogDateProps) => {
  const dateJS = new Date(date)
  const longMonth = dateJS.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })

  return (
    <h4 className={`${baseClassName}--date fr-date-badge ${extraClassNames}`}>
      <time dateTime={date}>{longMonth}</time>
    </h4>
  )
}
