import React from "react"
import { usePagination, DOTS } from "hooks/usePagination"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import "./pagination.module.scss"
import { LeftChevron, RightChevron } from "assets/helper/icons"

interface PaginationProps {
  onPageChange: (page: number) => void
  onNextPage: () => void
  onPreviousPage: () => void
  totalCount: number
  currentPage: number
  pageSize: number
  siblingCount?: number
  hasTextInBtnController?: boolean
}

// Next and Previous buttons could be chrevron icons - add a variant for this use case instead of removing next and forward directly
export const Pagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onNextPage,
  onPreviousPage,
  siblingCount = 1,
  hasTextInBtnController = false,
}: PaginationProps) => {
  const { size } = useWindowDimensions()

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 items in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === lastPage

  const getCurrentPageClassName = (pageNumber: number) => {
    if (pageNumber === currentPage) return " fr-pagination__page-link--active"
    return ""
  }

  return (
    <div className="fr-pagination u-flex-center-align">
      {/* Left navigation arrow */}
      <button
        className="fr-pagination__btn fr-pagination__btn--previous"
        onClick={!isFirstPage ? onPreviousPage : undefined}
        type="button"
        aria-label="Previous"
        disabled={isFirstPage}
      >
        <LeftChevron className="fr-pagination__btn--icon u-flex-center-align" />
        {hasTextInBtnController && <>Prev{size >= 1366 && `ious`}</>}
      </button>

      <ul className="fr-pagination__page-list u-flex-center-align">
        {paginationRange.map(pageNumber => {
          // If the pageItem is a DOT, render the DOTS unicode character

          if (pageNumber == DOTS) {
            return (
              <li
                key={`page-${pageNumber}`}
                className="fr-pagination__page-link fr-pagination__page-link--dots u-flex-center-align"
              >
                &#8230;
              </li>
            )
          }

          if (typeof pageNumber === "string") return // This line is here to make typescript happy about "..." since the pageNumber will always be a number in the next return block.

          // Render our Page Pills
          return (
            <li
              key={`page-${pageNumber}`}
              className={`fr-pagination__page-link ${getCurrentPageClassName(
                pageNumber
              )}`}
            >
              <button className="fr-pagination__page-link--btn u-flex-center-align" onClick={() => onPageChange(pageNumber)}>
                {pageNumber}
              </button>
            </li>
          )
        })}
      </ul>

      {/*  Right Navigation arrow */}
      <button
        className="fr-pagination__btn fr-pagination__btn--next u-flex-center-align"
        onClick={!isLastPage ? onNextPage : undefined}
        type="button"
        aria-label="Next"
        disabled={isLastPage}
      >
        {hasTextInBtnController && <>Next</>}
        <RightChevron className="fr-pagination__previous-btn--icon" />
      </button>
    </div>
  )
}
