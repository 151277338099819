import React from "react"
import { GoTriangleRight } from "react-icons/go"
import { Button } from "../../button"
import { Image } from "../../image/Image"
import { ImageBuilder } from "../../../_types/AssetsImage"
import { BlogCardContainer } from "./BlogCardContainer"
import "./blog-card.module.scss"
import { useLayout } from "contexts/components/LayoutContext"
import { BlogDate } from "../BlogDate/BlogDate"

interface BlogCardProps {
  image?: ImageBuilder
  heading?: string
  description?: string
  slug: string
  hasButton?: boolean
  hasDateTag?: boolean
  hasDateTitle?: boolean
  date?: string
  customOverlayCTAText?: string
  blockPosition?: number
}

export const BlogCard = ({
  heading,
  description,
  image,
  slug,
  date,
  hasButton,
  customOverlayCTAText,
  blockPosition,
}: BlogCardProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`

  return (
    <BlogCardContainer hasButton={hasButton} slug={path}>
      <div className={"fr-blog-card__image--container"}>
        {image && (
          <Image
            alt={image.description}
            className="fr-blog-card__image"
            image={image}
            objectFit="cover"
          />
        )}
        {customOverlayCTAText && (
          <div className="fr-blog-card__overlay u-flex-center-align">
            <p className="fr-blog-card__cta-tag u-flex-center-align">
              {customOverlayCTAText ? customOverlayCTAText : "Read more"}
              <GoTriangleRight size={20} style={{ marginLeft: "0.5rem" }} />
            </p>
          </div>
        )}
      </div>
      <div className="fr-blog-card__info--container">
        {date && <BlogDate date={date} baseClassName="fr-blog-card__info" extraClassNames="u-text--align-left" />}
        {heading && <h3 className="fr-blog-card__info fr-blog-card__info--heading u-text--align-left">{heading}</h3>}
        {description && (
          <p className="fr-blog-card__info fr-blog-card__info--description">{description}</p>
        )}

        {hasButton && (
          <Button
            to={path}
            variant="outline-secondary"
            extraClassNames="fr-blog-card__info fr-blog-card__info--btn"
          >
            Read More
          </Button>
        )}
      </div>
    </BlogCardContainer>
  )
}
