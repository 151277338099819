import React from "react"
import { AnchorNumber } from "./AnchorNumber"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { FormStub } from "./FormStub"
import { graphql } from "gatsby"
import { KontentFormStubBannerFormatted } from "./formStubBanner-types"
import "./form-stub.module.scss"
import { useLayout } from "contexts/components/LayoutContext"

interface StubProps {
  formStub: KontentFormStubBannerFormatted
  nextBlockHasHeading?: boolean
}

export const StubBanner = ({ formStub, nextBlockHasHeading }: StubProps) => {
  const { heading, buttons, subheading, fields } = formStub
  const marginBottomModifier = nextBlockHasHeading
  const { slug, isHomepage } = useLayout()
  const isContactPage = slug.includes("contact")

  const PhoneLink = () => {
    const {
      trackingNumbers,
      customTrackingNumberCtaButtonText,
      usePhoneNumberNotTrackingNumber,
    } = useGeneralSettings()

    const trackingNumberCTAText =
      customTrackingNumberCtaButtonText || "Or Call Today! "

    const { trackingNumber, phoneNumber, id } = trackingNumbers[0]

    if (usePhoneNumberNotTrackingNumber && !phoneNumber) return <></>
    if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return <></>
    return (
      <AnchorNumber
        key={id}
        phoneNumber={phoneNumber}
        trackingNumber={trackingNumber}
        showIcon={false}
        label={trackingNumberCTAText}
      />
    )
  }

  return (
    <section
      className={
        isHomepage
          ? `form-stub-banner  ${marginBottomModifier}`
          : `form-stub-banner form-stub-banner--no-background ${marginBottomModifier}`
      }
    >
      <div className="fr-container">
        <h2 className="form-stub-banner__heading">{heading}</h2>
        {subheading && (
          <p className="form-stub-banner__subheading">{subheading}</p>
        )}
        <div className="form-stub-banner__buttons">
          {isContactPage ? (
            <PhoneLink />
          ) : (
            <FormStub fields={fields} buttons={buttons} />
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FormStubBannerFragment on kontent_item_form_stub_banner {
    id
    elements {
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      heading {
        value
      }
      subheading {
        value
      }
      fields {
        value {
          ...FormFieldFragment
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
