import React, { ReactNode } from "react"
import { Link } from "gatsby"
import "./blog-card.module.scss"

interface BlogCardProps {
  slug?: string
  hasButton?: boolean
  children?: ReactNode
}

export const BlogCardContainer = ({
  hasButton,
  slug = "",
  children,
}: BlogCardProps) => {
  return (
    <Link to={slug} className="fr-blog-card-link">
      <article className="fr-blog-card">{children}</article>
    </Link>
  )
}
